import { render, staticRenderFns } from "./privacy.vue?vue&type=template&id=39c04973&scoped=true&"
import script from "./privacy.vue?vue&type=script&lang=js&"
export * from "./privacy.vue?vue&type=script&lang=js&"
import style0 from "./agreement.css?vue&type=style&index=0&id=39c04973&prod&scoped=true&lang=css&"
import style1 from "./privacy.vue?vue&type=style&index=1&id=39c04973&prod&scoped=true&lang=less&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "39c04973",
  null
  
)

export default component.exports