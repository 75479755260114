<template>
  <div>
    <!-- 顶部 -->
    <div class="agreement-header-wrap">
      <!-- PC -->
      <div class="pc-header">
        <div class="main">
          <a href="" target="_blank" class="logo">科汇云</a>
        </div>
      </div>
      <!-- H5 -->
      <!-- <div class="m-header">
        <i class="goback" onclick="javascript:history.back()"></i>
        <div class="title">科汇云会员服务协议</div>
      </div>-->
      <div class="agreement-blank"></div>
    </div>
    <!-- 内容 -->
    <div class="agreement-con">
      <h2><strong>隐私保护政策</strong></h2>
      <p class="liuhao">更新日期：2021年5月8日</p>
      <p class="liuhao">生效日期：2020年5月8日</p>
      <p>尊敬的用户：</p>
      <p>
        欢迎访问科汇云（以下简称“我们”）！您的信任对我们非常重要，我们深知个人信息对您的重要性，我们将会按照法律要求和业界成熟的安全标准，为您的个人信息提供相应的安全保护措施。
      </p>
      <p>
        我们希望通过《隐私保护政策》（以下简称“本政策”）向您清晰地介绍在使用我们的产品/服务时，详细了解我们对信息的收集、使用等方式，以便您更好地了解我们的服务并作出适当的选择。
      </p>
      <p>
        我们将逐一说明我们收集的您的个人信息类型及其对应用途，以便您了解我们针对某一特定功能所收集的具体个人信息的类别、使用理由及收集方式。除非按照相关法律法规要求必须收集的，拒绝提供这些信息仅会使您无法使用相关特定功能，但不影响你正常使用本平台的其他功能。
      </p>
      <p>
        为了在本政策下收集你的信息，或向你提供服务、优化我们的服务以及保障你的账号安全，我们将需要向你索取相关的权限；其中的敏感权限列如摄像头、麦克风等均不会默认开启，只有在您明确同意后才会向我们授权。<span
          class="cuti"
          >需要特别说明的是，获得敏感权限是我们收集特定信息的必要而非充分条件。我们获得具体某一项敏感权限并不代表我们必然会收集你的相关信息；即使我们已经获得敏感权限，也仅在必要时、根据本政策来收集你的相关信息。</span
        >
      </p>
      <p class="cuti">
        【特别提示】请您在使用我们提供的各项产品/服务前，请仔细阅读并充分理解本政策（重点内容我们已将字体加粗请您特别关注）并作出相应选择。一旦您使用或继续使用我们的产品/服务时，即意味着您同意我们按照隐私保护声明处理您的相关信息。
      </p>
      <p>
        本政策的全部条款属于《科汇云服务协议》的一部份，除另有约定外，本政策所用术语与《科汇云服务协议》中的术语具有相同的涵义。若您同意《科汇云服务协议》并使用我们的产品和/或服务，即表示您认同我们在本政策中所述内容，且已经同意我们按照本政策来收集和使用您的个人信息。
      </p>
      <p class="cuti">
        如对本政策有任何疑问，您可以通过本政策中“如何联系我们”中提供的方式联系我们。
      </p>
      <p>如果您想了解更加详尽的信息，请根据以下索引阅读相应章节：</p>
      <p>1.我们如何收集和使用您的个人信息</p>
      <p>2.我们如何使用 Cookie 和同类技术</p>
      <p>3.我们如何共享、转让、公开披露您的个人信息</p>
      <p>4.接入第三方SDK目录</p>
      <p>5.我们如何保存及保护您的个人信息</p>
      <p>6您如何管理自己的信息</p>
      <p>7.我们如何处理未成年人的个人信息</p>
      <p>8.注销</p>
      <p>9.隐私政策的修订</p>
      <p>10.如何联系我们</p>

      <p class="sihao wubiaoqian">我们如何收集和使用您的个人信息</p>
      <p class="wubiaoqian">
        1.在你使用科汇云服务的过程中，科汇云会按照如下方式收集你在使用服务时主动提供或因为使用服务而产生的信息，用以向你提供服务、优化我们的服务以及保障你的账号安全：
      </p>
      <p>
        1.1
        当您注册本平台账号时，我们需要您至少向我们提供您的手机号码、姓名（单位名称）和登录密码。收集这些信息是为了帮助您完成本平台账号注册，保护您的账号安全。<span
          class="cuti"
          >姓名、手机号码属于敏感信息，收集此类信息是为了满足相关法律法规的网络实名制要求。</span
        >如果您仅需使用浏览、搜索等基本服务，您不需要注册成为我们的用户及提供上述信息。您注册的账号可以登录本平台并获得相关服务或使用相关功能的权限。
      </p>
      <p>
        您可以自主选择手动填写账号与密码的方式完成登录。您也可以选择本平台“手机快速登录”功能，通过手机短信验证方式使用本平台为您提供的快捷注册与登录服务。当您注册成为会员后三天内，本平台会通过您留下的电话号码对您进行一次电话回访，以便更好的匹配您的需求为您提供优质的服务。如后续您在本平台的活跃度较高，本平台将对您进行一次电话回访调研，用于了解您对本平台的意见和建议，优化您的使用体验。<span
          class="cuti"
          >您确认，在您完成注册成为本平台会员后本平台将视为您同意本平台的电话回访。</span
        >
      </p>
      <p>
        如果您使用QQ、微信、微博关联登录本平台的，我们会收集您QQ、微信、微博的唯一标识、头像、昵称，用于保存您的登录信息，同时通过手机号码验证的方式与本平台账号绑定，使您在使用不同设备登录时能够给同步您的数据。
      </p>
      <p>
        1.2当您需要进行服务商入驻时，您需要完善您的基本资料（必填项：姓名（用户名）、所属行业、所在地、手机号、邮箱、所在单位。选填项：职务、个人头像、个人简介、固定电话、详细地址）。<span
          class="cuti"
          >收集此类信息是为了统计数据进行分类，以便更有针对性地向您提供新的服务和交易机会。
        </span>
      </p>
      <p>
        1.3在您使用本平台交易系统服务、或服务商入驻时，为在线交易创建安全可靠的环境，本平台会要求您完成实名认证。
      </p>
      <p class="yijibiaoqian">
        1.3.1如果您是个人，在您使用实名认证的功能或服务时，您需要提供您的真实身份信息（如真实姓名、身份证号码、电话号码等资料）。
      </p>
      <p class="yijibiaoqian">
        1.3.2如果您是企业，在您使用实名认证的功能或服务时，您需要提供企业真实信息（如企业名称、统一社会信用代码、企业法人姓名及身份证号码等）。
      </p>
      <p>
        为了验证您提供信息的准确性和完整性，保障您的账号安全或交易安全，本平台会要求您提供有效的身份证件与银行账户信息，并与合法留存您的信息的国家机关、金融机构、企事业单位进行核对。
      </p>
      <p>
        <span class="cuti"
          >收集上述信息的目的是为了认证您的身份，保障合同效力。如果您不使用特定产品和/或服务，则无需提供相关信息。</span
        >
      </p>
      <p>
        1.4当您需进行线上交易时，您需要提供您的开户银行、开户行所在地、银行账号、银行卡预留手机号，我们会把您的银行卡号、有效期在内的银行卡支付并要信息与您选择的相应提供支付的金融机构共享。
      </p>
      <p>
        为了验证您提供信息的准确性和完整性，保障您的账号安全或交易安全，本平台会要求您提供有效的身份证件与银行账户信息，并与合法留存您的信息的国家机关、金融机构、企事业单位进行核对。
      </p>
      <p>
        1.5.
        为您的产品和/或服务信息在展示过程中以及您参加活动过程中，帮助您与其他用户在线互动，或帮助您获取功能权限。
      </p>
      <p>
        您在发布信息（如技术成果、技术需求、研发服务、工研产品等）时，在报名参加在线展会或线下活动时，为满足向您提供产品和/或服务之目的，除注册时提供的信息外，您可能还需要进一步向本平台提供与上述产品和/或服务的相关的信息（例如您的真实姓名、性别、出生年月、头像、简介、工作单位、所在地、联系信息、技术成果证明、研发产品/服务证明等），您提供上述信息并使用本平台的产品和/或服务则表明您同意并授权本平台使用您提供的个人信息。本平台会在您授权同意的前提下展示您发布内容的同时展示您的真实姓名、头像、所在地等信息，以帮助您与访问用户可以直接通过在线即时通讯软件对接洽谈。 
      </p>
      <p>
        如果您主动申请以技术专家身份入驻本平台，则表明您同意并授权本平台接收并使用您的个人信息，本平台会在隐私保护原则基础上披露和展示您的技术专家相关信息，您需保证该技术专家信息的真实性。
      </p>

      <p class="wubiaoqian">
        2.
        为了提升本平台的用户体验，本平台可能会收集关于您使用产品和/或服务（包括使用方式）过程中的信息，此类信息包括：
      </p>
      <p>
        2.1
        日志信息：当您使用本平台产品和/或服务时，本平台的服务器会自动记录一些信息，例如您对本平台的产品和/或服务的使用情况、IP地址、所访问服务的URL、浏览器的类型。
      </p>
      <p>
        2.2本平台还会记录您在使用本平台的产品和/或服务时提供、形成或留存的信息。例如您在使用搜索功能时输入的搜索关键词信息或点击具体的链接，您参与其他产品运营活动时提交的信息。
      </p>
      <p>
        本平台可能使用您的账号信息、设备信息、服务日志信息以及本平台合作方在获得您授权或依法可以共享的信息，用于判断账户安全、进行身份验证、检测及防范安全事件。
      </p>
      <p class="cuti">
        本平台收集、使用的上述信息进行了匿名化处理，数据分析仅对应特定的、无法直接关联您身份的编码，不会与您的真实身份相关联。
      </p>

      <p class="wubiaoqian">
        3.
        本平台会利用收集的信息（例如您的手机号码、电子邮件地址）直接与您沟通或互动。例如，如果您使用平台的产品和/或服务时，本平台可能会向您发送通知。或者，本平台可能与您联系，让您了解本平台为您提供的服务即将发生的变化或改进，邀请您参加新产品的体验等。
      </p>

      <p class="wubiaoqian">
        4.科汇云有可能自动接收并记录您的浏览器和计算机上的信息，其中包括您的 IP
        地址、科汇云cookie
        中的信息、软硬件特征信息以及您需求的网页记录，目的是为了向您提供更加精准和个性化的服务和内容。
      </p>

      <p class="wubiaoqian">
        5.
        您提供发布的供贸易的物品信息或报价将被显示在公共区域，这对任何用户来说是公开的。请注意所有在这些场所公开的信息会成为公共信息，请您在决定公布您的个人信息及物品信息前仔细考虑。
      </p>

      <p class="wubiaoqian">6.依法豁免征得同意收集和使用的个人信息。</p>

      <p>
        为了预防恶意程序、确保运营质量及效率，本平台会收集安装的应用信息或正在运行的进程信息、应用程序的总体运行、使用情况与频率、应用崩溃情况、总体安装使用情况、性能数据、应用来源。
      </p>
      <p class="cuti">
        请您理解，在下列情形中，根据法律法规及相关国家标准，本平台收集和使用您的个人信息无需征得您的授权同意：
      </p>

      <p class="yijibiaoqian">6.1. 与国家安全、国防安全直接相关的。</p>
      <p class="yijibiaoqian">
        6.2. 与公共安全、公共卫生、重大公共利益直接相关的。
      </p>
      <p class="yijibiaoqian">
        6.3. 与犯罪侦查、起诉、审判和判决执行等直接相关的。
      </p>
      <p class="yijibiaoqian">
        6.4.
        出于维护个人信息主体或其他个人的生命、财产等重大合法权益但又很难得到本人同意的。
      </p>
      <p class="yijibiaoqian">
        6.5. 所收集的您的个人信息是您自行向社会公众公开的。
      </p>
      <p class="yijibiaoqian">
        6.6.
        从合法公开披露的信息中收集的您的个人信息的，如合法的新闻报道、政府信息公开等渠道。
      </p>
      <p class="yijibiaoqian">6.7. 根据您的要求签订或履行合同所必需的。</p>
      <p class="yijibiaoqian">
        6.8.
        用于维护软件及相关服务的安全稳定运行所必需的，例如发现、处置软件及相关服务的故障。
      </p>
      <p class="yijibiaoqian">6.9. 为合法的新闻报道所必需的。</p>
      <p class="yijibiaoqian">
        6.10.
        学术研究机构基于公共利益开展统计或学术研究所必要，且对外提供学术研究或描述的结果时，对结果中所包含的个人信息进行匿名化处理的。
      </p>
      <p class="yijibiaoqian">6.11. 法律法规规定的其他情形。</p>
      <p class="cuti">
        请注意，单独或与其他信息相结合无法识别您的身份或者与您直接建立联系的信息，不属于个人信息。如果我们将单独无法与任何特定个人建立联系的信息与其他信息结合用于识别自然人身份，或者将其与个人信息相结合使用，则在结合使用期间，此类信息将被视为个人信息。
      </p>

      <p class="sihao wubiaoqian">我们如何使用 Cookie 和同类技术</p>

      <p class="wubiaoqian">Cookie的使用</p>
      <p>
        为确保网站正常高效运转、为您获得更轻松的访问体验、向您推荐您可能感兴趣的内容，我们会在您的设备终端/系统上存储名为
        Cookie的小数据文件。Cookie会帮助您在后续访问我们网站时调用您的信息，简化您填写个人信息(例如一键登录等)的流程;为您提供安全购物的偏好设置;帮助您优化对广告的选择与互动;保护您的数据安全等。我们不会将
        Cookie用于本隐私政策所述目的之外的任何用途。您可根据自己的偏好管理或删除
        Cookie。您可以清除计算机或移动设备上保存的所有
        Cookie，您有权接受或拒绝Cookie。大多数浏览器会自动接受Cookie，但您通常可根据自己的需要来修改浏览器的设置以拒绝Cookie;另外，您也可以清除软件内保存的所有Cookie。但您可能因此无法完全体验我们某些便捷性和安全性的服务功能。
      </p>
      <p class="wubiaoqian">
        我们通过得到的Cookie信息，可以在我们网站提供个性化服务。
      </p>

      <p class="sihao">我们如何共享、转让、公开披露您的个人信息</p>
      <p>
        除以下情形外，未经您同意，本平台不会向任何无关第三方提供、出售、出租、分享和交易本平台所获取的用户个人信息：
      </p>
      <p class="wubiaoqian">1. 共享</p>
      <p>在获得您的明确同意后，本平台会与其他方共享您的个人信息。</p>
      <p>
        本平台可能会根据法律法规规定，或按政府主管部门的强制性要求或司法裁定，对外共享您的个人信息。
      </p>
      <p>
        仅为实现本政策中声明的目的，本平台可能会与合作伙伴共享您的某些个人信息，以提供更好的客户服务和用户体验。本平台仅会出于合法、正当、必要、特定、明确的目的共享您的个人信息，并且只会共享与提供服务相关的个人信息。
      </p>

      <p class="wubiaoqian">2. 转让</p>
      <p>本平台不会转让您的个人信息给任何其他第三方，除非征得您的明确同意。</p>
      <p>
        随着本平台业务的持续发展，本平台可能与合作伙伴合作开发或运营互联网平台，为了拓展您的产品和/或服务的宣传渠道，您同意本平台可以和提供与您的个人信息相关的产品和/或服务的互联网平台共享您的个人信息。在发生前述变更时，本平台将按照法律法规及不低于本政策所载明的安全标准要求继受方保护您的个人信息，否则本平台将要求继受方重新征得您的授权同意。
      </p>

      <p class="wubiaoqian">3. 披露</p>
      <p>
        3.1
        您同意本平台可以披露或使用您的个人信息以用于识别和/或确认您的身份，或解决争议，或有助于确保网站安全、限制欺诈、非法或其他刑事犯罪活动，以履行本平台的服务协议。
      </p>
      <p>
        3.2
        您同意本平台可以披露或使用您的个人信息以保护您的生命、财产之安全或为防止严重侵害他人之合法权益或为公共利益之需要。
      </p>
      <p>
        3.3
        您同意本平台可以披露或使用您的个人信息以改进本平台的服务，并使本平台的服务更能符合您的要求，从而使您在使用本平台服务时得到更好的使用体验。
      </p>
      <p>
        3.4您同意本平台利用您的个人信息与您联络，并向您提供您感兴趣的信息，如技术成果、研发产品等信息。您接受《服务协议》和本政策即为明示同意收取这些资料。
      </p>
      <p class="cuti">
        3.5
        您同意本平台将您与对方达成交易意向、成功对接或者参加报名本平台某项活动等情况在本平台上进行公告。
      </p>
      <p class="cuti">
        3.6.
        您同意本平台的网站公布您提交的技术成果或服务的报价，而其他用户可以查询这些报价、技术成果和服务。
      </p>
      <p>
        3.7
        当本平台被法律强制或依照政府/检察院/法院因识别涉嫌侵权行为人的要求而提供您的信息时，本平台将善意地披露您的资料。
      </p>

      <p class="wubiaoqian">
        4. 依法豁免征得同意共享、转让、公开披露的个人信息。
      </p>
      <p>
        请您理解，在下列情形中，根据法律法规及国家标准，本平台共享、转让、公开披露您的个人信息无需征得您的授权同意：
      </p>
      <p>4.1. 与国家安全、国防安全直接相关的。</p>
      <p>4.2 与公共安全、公共卫生、重大公共利益直接相关的。</p>
      <p>4.3与犯罪侦查、起诉、审判和判决执行等直接相关的。</p>
      <p>
        4.4
        出于维护您或其他个人的生命、财产等重大合法权益但又很难得到本人同意的。
      </p>
      <p>4.5您自行向社会公众公开的个人信息。</p>
      <p>
        4.6
        从合法公开披露的信息中收集个人信息的，如合法的新闻报道、政府信息公开等渠道。
      </p>

      <p class="sihao wubiaoqian">接入第三方SDK目录</p>
      <p>
        为保障本平台的稳定运行或实现相关功能，我们可能会接入由第三方提供的软件开发包（SDK）。我们会对合作方获取个人信息的SDK进行严格的安全监测，以保护您的个人信息安全。
      </p>
      <p>
        我们对接入的相关第三方SDK在以下目录中列明。您可以通过相关链接查看第三方的数据使用和保护规则。请注意，第三方SDK可能由于版本升级、策略调整等原因导致其个人信息处理类型发生变化，请以其公示的官方说明为准。
      </p>
      <div class="wubiaoqian">
        <p>1.QQ SDK</p>
        <p>使用目的：1、帮助用户将特定内容分享到QQ；2、用户使用QQ授权登录</p>
        <p>数据类型：用户基本信息、网络状态</p>
        <p>
          开发文档链接：<a
            href="https://wiki.open.qq.com/wiki/website/API%E5%88%97%E8%A1%A8"
            >https://wiki.open.qq.com/wiki/website/API列表</a
          >
        </p>
        <p>
          开发者协议链接：<a
            href="https://wiki.open.qq.com/wiki/website/%E5%BC%80%E5%8F%91%E8%80%85%E5%8D%8F%E8%AE%AE"
            >https://wiki.open.qq.com/wiki/website/开发者协议</a
          >
        </p>

        <p>2.微信 SDK</p>
        <p>
          使用目的：1、帮助用户将特定内容分享到微信；2、用户使用微信授权登录
        </p>
        <p>数据类型：用户基本信息、网络状态</p>
        <p>
          开发文档链接：<a
            href="https://developers.weixin.qq.com/doc/oplatform/Website_App/WeChat_Login/Wechat_Login.html"
            >https://developers.weixin.qq.com/doc/oplatform/Website_App/WeChat_Login/Wechat_Login.html</a
          >
        </p>
        <p>
          开发者协议链接：<a
            href="https://open.weixin.qq.com/cgi-bin/frame?t=news/protocol_developer_tmpl"
            >https://open.weixin.qq.com/cgi-bin/frame?t=news/protocol_developer_tmpl</a
          >
        </p>
        <p>3.微博 SDK</p>
        <p>
          使用目的：1、帮助用户将特定内容分享到微博；2、用户使用微博授权登录
        </p>
        <p>数据类型：用户基本信息、网络状态</p>
        <p>
          开发文档链接：<a
            href="https://open.weibo.com/wiki/%E7%BD%91%E7%AB%99%E6%8E%A5%E5%85%A5%E4%BB%8B%E7%BB%8D"
            >https://open.weibo.com/wiki/网站接入介绍</a
          >
        </p>
        <p>
          开发者协议链接：<a
            href="https://open.weibo.com/wiki/%E5%BC%80%E5%8F%91%E8%80%85%E5%8D%8F%E8%AE%AE"
            >https://open.weibo.com/wiki/开发者协议</a
          >
        </p>

        <p>4.网易云IM</p>
        <p>使用目的：即时通讯、音视频通话</p>
        <p>数据类型：通话、存储、相机、设备标识信息、网络状态</p>
        <p>
          开发文档链接：<a
            href="http://dev.yunxin.163.com/docs/product/IM%E5%8D%B3%E6%97%B6%E9%80%9A%E8%AE%AF/%E6%96%B0%E6%89%8B%E6%8E%A5%E5%85%A5%E6%8C%87%E5%8D%97"
          >
            http://dev.yunxin.163.com/docs/product/IM即时通讯/新手接入指南
          </a>
        </p>
        <p>
          隐私政策链接：<a href="https://netease.im/clauses?serviceType=3"
            >https://netease.im/clauses?serviceType=3</a
          >
        </p>
      </div>

      <p class="sihao wubiaoqian">我们如何保存及保护您的个人信息</p>
      <p class="wubiaoqian">1. 存储地点。</p>
      <p>
        您的个人信息均储存于中华人民共和国境内。如部分产品或服务涉及跨境，本平台需要向境外传输您的个人信息，本平台会严格按照法律法规的规定执行，并保证您的个人信息安全。
      </p>
      <p class="wubiaoqian">2. 存储期限。</p>
      <p>
        除非依据法律法规或双方约定，我们仅会在实现目的所必需的最短时间内留存您的相关个人信息。在您主动注销账号时，我们将根据法律法规的要求尽快删除您的个人信息或匿名化处理。。
      </p>
      <p class="wubiaoqian">3.安全措施。</p>
      <p>
        3.1本平台非常重视您个人信息的安全，将努力采取合理的安全措施（包括技术方面和管理方面）来保护您的个人信息，防止您提供的个人信息被不当使用或未经授权的情况下被访问、公开披露、使用、修改、损坏、丢失或泄漏。
      </p>
      <p>
        3.2本平台会使用不低于行业同行的加密技术、匿名化处理及相关合理可行的手段保护您的个人信息，并使用安全保护机制防止您的个人信息遭到恶意攻击。
      </p>
      <p>
        3.3本平台会建立专门的安全部门、安全管理制度、数据安全流程保障您的个人信息安全。本平台会对员工进行数据安全的意识培养和安全能力的培训和考核，加强员工对于保护个人信息重要性的认识。本平台部署访问控制机制，对处理个人信息的员工进行身份认证及权限控制，并会与接触您个人信息的员工、合作伙伴签署保密协议，明确岗位职责及行为准则，确保只有授权人员才可访问个人信息。若有违反保密协议的行为，会被追究相关责任。
      </p>
      <p>
        3.4
        您知悉并理解，您接入本平台的服务所用的系统和通讯网络，有可能因本平台可控范围外的因素而出现问题。因此，本平台强烈建议您采取积极措施保护个人信息的安全，包括但不限于使用复杂密码、定期修改密码、不将自己的账号密码及相关个人信息透露给他人。
      </p>
      <p>
        3.5本平台会制定应急处理预案，并在发生用户信息安全事件时立即启动应急预案，努力阻止这些安全事件的影响和后果扩大。一旦发生用户信息安全事件（泄露、丢失）后，本平台将按照法律法规的要求，及时向您告知：安全事件的基本情况和可能的影响、本平台已经采取或将要采取的处置措施、您可自主防范和降低风险的建议、对您的补救措施。本平台将及时将事件相关情况以推送通知、邮件、短信或其他相关形式告知您，难以逐一告知时，本平台会采取合理、有效的方式发布公告。同时，本平台还将按照相关监管部门要求，上报用户信息安全事件的处置情况。
      </p>
      <p>
        3.6尽管已经采取了上述合理有效措施，并已经遵守了相关法律规定要求的标准，但请您理解，由于技术的限制以及可能存在的各种恶意手段，在互联网行业，即便竭尽所能加强安全措施，也不可能始终保证信息百分之百的安全，本平台将尽力确保您提供给本平台的个人信息的安全性。
      </p>
      <p>
        3.7本平台含有到其他网站的链接、插件和应用程序的链接，本平台可能在任何需要的时候增加商业伙伴或共用品牌的网站的链接，点击此类链接或启用这些连接可能允许第三方收集或分享关于您的信息。如果您访问任何第三方网站的链接（请注意，这些网站有自己的隐私保护声明或政策），本平台不对那些网站的隐私保护措施或其数据处理活动负任何责任或义务。
      </p>

      <div class="wubiaoqian">
        <p class="sihao">您如何管理自己的信息</p>
        <p>
          1.您可以在使用我们服务的过程中，访问、修改和删除您提供的注册信息和其他个人信息，也可按照通知指引与我们联系。您访问、修改和删除个人信息的范围和方式将取决于您使用的具体服务。
        </p>
        <p>
          2.我们将按照本政策所述，仅为实现我们产品或服务的功能，收集、使用您的信息。
        </p>
        <p>
          3.如您发现我们违反法律、行政法规的规定或者双方的约定收集、使用您的个人信息，您可以要求我们删除。
        </p>
        <p>
          4.如您发现我们收集、存储的您的个人信息有错误的，您也可以要求我们更正。
        </p>
        <p>5.请通过本政策列明的联系方式与我们联系。</p>
        <p>
          6.在您访问、修改和删除相关信息时，我们可能会要求您进行身份验证，以保障帐号的安全。
        </p>
        <p>
          7.请您理解，由于技术所限、法律或监管要求，我们可能无法满足您的所有要求，我们会在合理的期限内答复您的请求。
        </p>
      </div>

      <p class="sihao wubiaoqian">我们如何处理未成年人的个人信息</p>
      <p>
        如果您未满18周岁，您无权使用本公司服务，因此我们希望您不要试图注册成为会员。
      </p>
      <p>
        如果您未满18周岁但希望进行交易，我们建议通过您的父母或其他监护人注册成为会员的方式进行交易。
      </p>
      <p class="cuti">
        如果您未满18周岁，在实名认证的时候提供虚假的证件或证明文件，由此产生的一切法律责任将由您及您的父母或其他监护人承担。
      </p>

      <p class="sihao wubiaoqian">注销</p>
      <p>
        您可以在申请注销账号。但本平台为了保护您或他人的合法权益会结合您对本平台中各产品和/或服务的使用情况判断是否支持您的注销请求。
      </p>
      <p>
        本平台可能会先要求您验证自己的身份，然后再处理您的请求。为保障安全，您需要提供书面请求，或以其他方式证明您的身份。
      </p>
      <p class="cuti">
        您知悉并理解，注销账号的行为是不可逆的行为，当您注销账号后，本平台将删除有关您的相关信息或进行匿名化处理，但法律法规另有规定的除外。一旦您注销本平台账号，将无法使用本平台部分产品和/或服务，因此请您谨慎操作。
      </p>

      <p class="sihao wubiaoqian">隐私政策的修订</p>
      <p>
        我们可能会适时对本政策进行修订。当政策的条款发生变更时，我们会在您登录及版本更新时以推送通知、弹窗的形式向您展示变更后的政策。请您注意，只有在您点击弹窗中的同意按钮后，我们才会按照更新后的政策收集、使用、存储您的个人信息。
      </p>

      <p class="sihao wubiaoqian">如何联系我们</p>
      <p>
        当您有其他的投诉、建议、未成年人个人信息相关问题或您对本隐私保护声明，请通过以下方式与我们联系，我们将在15个工作日内回复您的请求：
      </p>
      <p class="wubiaoqian">
        1.您可以联系我们的客服热线：18580039998 来进行反馈。
      </p>
      <p class="wubiaoqian">
        2.我们还设立了客服邮箱：277729968@qq.com，欢迎您随时反馈您的意见。
      </p>

      <p class="sihao wubiaoqian">名词解释</p>
      <p>
        a.
        个人信息：指以电子或者其他方式记录的能够单独或者与其他信息结合识别特定自然人身份或者反映特定自然人活动情况的各种信息。（出自于
        GB/T 35273-2020《信息安全技术个人信息安全规范》）
      </p>
      <p>
        本政策中涉及的个人信息包括个人基本资料（包括个人姓名、生日、性别、住址、个人电话号码、电子邮箱）、身份信息（包括身份证信息、企业营业执照信息）、个人生物识别信息（面部特征）、网络身份标识信息（包括系统账号、IP地址、邮箱地址以及与前述有关的密码、口令、口令保护答案、用户个人数字证书）、个人财产信息（包括银行账号、鉴别信息（口令）、资金信息（包括资金数量、收益、余额）、交易和消费记录、流水记录、虚拟货币（如消费型余额、优惠券）、虚拟交易、兑换码等虚拟财产信息）、通讯录信息、个人上网记录（包括网站浏览记录、软件使用记录、点击记录）、个人常用设备信息（包括硬件序列号、硬件型号、设备MAC地址、操作系统类型、软件列表、唯一设备识别码（如IMEI/androidID/IDFA/OPENUDID/GUID、SIM卡IMSI信息等在内的描述个人常用设备基本情况的信息）、个人位置信息（包括大概地理位置、精准定位信息）。本平台实际具体收集的个人信息种类以上文描述为准。
      </p>
      <p>
        b.个人敏感信息：指一旦泄露、非法提供或滥用可能危害人身和财产安全，极易导致个人名誉、身心健康受到损害或歧视性待遇等的个人信息。（出自于GB/T
        35273-2017《信息安全技术个人信息安全规范》）
      </p>
      <p>
        本政策中涉及的个人敏感信息包括您的财产信息（包括银行账号、鉴别信息（口令）、资金信息（包括资金数量、收益、余额）、交易和消费记录、流水记录、虚拟货币（如消费型余额、优惠券）、虚拟交易、兑换码虚拟财产信息））、个人生物识别信息（面部特征）、个人身份信息（包括公民身份证号码信息）、网络身份识别信息（包括系统账号、邮箱地址以及与前述有关的密码、口令、口令保护答案、用户个人数字证书）、其他信息（包括通讯录信息、个人电话号码、精准定位信息、收货地址、网页浏览记录）。
      </p>
      <p>
        您同意您的个人敏感信息按本协议所述的目的和方式来处理。本平台实际具体收集的个人敏感信息种类以上文描述为准。相比其他个人信息，个人敏感信息可能对您的个人权益影响更大。本平台在此善意的提醒您需在谨慎考虑后再向本平台提供您的个人敏感信息。
      </p>
      <p>
        c.
        匿名化处理:指对个人信息进行技术处理，使得个人信息主体无法被识别，且处理后的信息不能被复原，是数据挖掘中隐私保护的最主要的一种技术手段。
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: "member",
  data() {
    return {};
  },
  created() {},
  methods: {},
};
</script>
 <style scoped src="./agreement.css"></style>
<style scoped lang="less">
.xiaoer {
  font-size: 24px;
}
.sihao {
  font-size: 18px;
  line-height: 28px;
  font-weight: bold;
}
.wuhao {
  font-size: 14px;
}
.liuhao {
  font-size: 10px;
}
.cuti {
  font-weight: bold;
  letter-spacing: -1px;
}
.wubiaoqian {
  text-indent: 0;
}
.yijibiaoqian {
  text-indent: 35px;
}
.font-red {
  color: red;
}
.underline {
  text-underline: inherit;
}
a {
  word-wrap: break-word;
   text-decoration: underline;
}
</style>
